<template>
  <div>
    <ribbon class="has-background-white">
      <div class="column is-12 has-text-centered">
        <img
          src="/assets/images/interface/icons/013-affiliate.png"
          width="64"
        />
        <h2 class="is-size-3">Fixed.net affiliate program</h2>
      </div>
    </ribbon>

    <ribbon class="has-inset-shadow-top">
      <aff-account-provider :aff-account-ref="affAccountRef">
        <template
          v-slot:default="{
            affAccountData,
            $requestWithdrawal,
            $format
          }"
        >
          <div class="columns is-marginless is-multiline">
            <!-- Left column -->
            <div class="column is-12 is-4-widescreen">
              <div class="box content has-padding-200">
                <p class="subtitle is-size-4 has-margin-bottom-100">
                  How does it work?
                </p>

                <p>
                  The fixed.net affiliate program is free to join and open to
                  everyone. You can earn <strong>$35</strong> to
                  <strong>$100 USD</strong> per sale when referring new
                  customers to our platform. For every customer you refer,
                  you'll earn a
                  <a href="https://fixed.net/affiliates" target="_blank"
                    ><u>fixed rate commission</u></a
                  >
                  on orders they place within their first
                  {{ $_.get(affAccountData, "referralWindow", 60) }} days.
                </p>

                <p>
                  To ensure we reward the most active and effective affiliates,
                  we calculate a rolling commission tier based on your activity
                  and efforts from the last 30 days. For example, make 5+
                  referrals in this timeframe and you'll trigger a higher
                  commission rate. All commissions are recorded in USD and
                  become available to withdraw 60 days after allocation. If an
                  order is cancelled within this 60 day period, the commission
                  will not be paid.
                </p>

                <p>
                  You can withdraw funds from your
                  <strong>available balance</strong> at any time by
                  <a @click="$requestWithdrawal()"
                    ><u>raising a support ticket</u></a
                  >. Payments are made in USD, although conversion to another
                  currency can be requested if preferred. To get started, simply
                  share your unique affiliate link and be sure to tell others
                  about our 5-star rated service.
                </p>
              </div>
            </div>

            <!-- Right column -->
            <div
              class="column is-12 is-8-widescreen columns is-multiline is-align-content-flex-start"
            >
              <div class="column is-12 affiliate-stats">
                <div class="box">
                  <p>Pending Balance</p>
                  <p class="title is-size-3 has-text-info">
                    {{ $format($_.get(affAccountData, "balancePending")) }}
                  </p>
                </div>
                <div class="box">
                  <p>Available Balance</p>
                  <p class="title is-size-3 has-text-success">
                    {{ $format($_.get(affAccountData, "balanceAvailable")) }}
                  </p>
                </div>
                <div class="box">
                  <p>Balance Withdrawn</p>
                  <p class="title is-size-3">
                    {{ $format($_.get(affAccountData, "balanceWithdrawn")) }}
                  </p>
                </div>
              </div>

              <div class="column is-12">
                <div class="box">
                  <aff-link-input />
                </div>
              </div>

              <div class="column is-12">
                <collapse title="Earnings (last 30 days)">
                  <aff-earnings-chart />
                </collapse>
              </div>
            </div>
          </div>
        </template>
      </aff-account-provider>
    </ribbon>
  </div>
</template>

<script>
export default {
  name: "AffiliateProgram",
  components: {
    "aff-account-provider": () =>
      import("@shared/affiliate/_affAccountProvider"),
    "aff-link-input": () => import("@shared/affiliate/_affLinkInput"),
    "aff-earnings-chart": () =>
      import("@shared/affiliate/_earningLast30DaysChart")
  },
  computed: {
    affAccountRef() {
      return this.$_.get(this.$store.getters["user/user"](), "affAccountRef");
    }
  },
  mounted() {
    this.$bus.$emit("scrollToTop");
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.is-align-content-flex-start {
  align-content: flex-start;
}
.affiliate-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 0.75rem;
  .box {
    margin: 0;
    display: flex;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    p {
      width: 100%;
    }
  }
}
</style>
